import "./App.css";
import React, { Component } from "react";
import { Home } from "./Components/Home";

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <div className="App">
        <Home />
      </div>
    );
  }
}
// function App() {
//   return (
//     <div className="App">
//       <Home />
//     </div>
//   );
// }

// export default App;
