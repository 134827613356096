import React from "react";
import AuthLogin from "./AuthLogin";

import { Navbar, Container, NavItem } from "react-bootstrap";

const IENavFooter = ({ ReturnLogin }) => {
  return (
    <>
      <Navbar className="bg-body-tertiary">
        <Container>
          <NavItem>
            <AuthLogin ReturnLogin={ReturnLogin} />
          </NavItem>
          <NavItem className="justify-content-end">
            <a href="https://discord.com/invite/sRwTx63Azr" target="_blank" rel="noreferrer">
              <img alt="" src={require("../icons/discord.png")} width="40" height="40" className="d-inline-block align-top" />
            </a>
            <a href="https://x.com/iee_phil" target="_blank" rel="noreferrer">
              <img alt="" src={require("../icons/twitter.png")} width="40" height="40" className="d-inline-block align-top" />
            </a>
            <a href="https://bsky.app/profile/judgephil.lorcana.community" target="_blank" rel="noreferrer">
              <img alt="" src={require("../icons/bluesky.jpg")} width="40" height="40" className="d-inline-block align-top" />
            </a>
            <a href="https://buymeacoffee.com/ieenchanted" target="_blank" rel="noreferrer">
              <img alt="" src={require("../icons/buymeacoffee.png")} width="40" height="40" className="d-inline-block align-top" />
            </a>
          </NavItem>
        </Container>
      </Navbar>
    </>
  );
};

export default IENavFooter;
